import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

export const metaFactory = (): MetaLoader => {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' :: ',
    applicationUrl: 'redecorarq.com.br',
    applicationName: 'Re.decorarq',
    defaults: {
      title: 'Redecora Arquitetura',
      description: 'Escritório de design de interiores e arquitetura prestando serviço de decoração e reforma. Caso esteja procurando um serviço de decoração, consultoria, obra, reforma e arquitetura a Redecorarq te ajuda',
      'og:site_name': 'Redecora Arquitetura',
      'og:image': 'https://redecorarq.com.br/assets/img/logo_header.png',
      'og:type': 'website',
      'og:locale': 'pt_BR',
    },
  });
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    BrowserAnimationsModule, 
    FontAwesomeModule,
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
