import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'manutencao',
    pathMatch: 'full'
  },
  {
    path: 'manutencao',
    loadChildren: () => import('./pages/public/maintenance/maintenance.module').then( m => m.MaintenancePageModule),
    canActivate: [MetaGuard],
    data: {
      meta: {
        robots: 'index, follow',
        title: 'Redecora Arquitetura',
        override: true,
        // description: 'Decoração, Consultoria, Obra, Reforma, Arquitetura',
        tags: 'Decoração, Consultoria, Obra, Reforma, Arquitetura'
      },
    },
  },
  {
    path: '**',
    redirectTo: 'manutencao',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
